<template>
  <div>
    <footer class="footer" :class="$route.path == '/' ? 'mt-0' : 'mt-4'">
      <div class="container">
        <div class="row gy-4 pt-4 footer__main">
       
          <div class="col-12 pt-3">
              <div class="row">
                <div class="col-6 col-lg">
                  <div class="widget-link  s1">
                    <h6 class="title">About </h6>
                    <ul class="">
                      <li> <router-link to="#"> About us</router-link></li>
                      <li> <router-link to="#">Careers</router-link></li>
                      <li> <router-link to="#"> Terms of Service</router-link></li>
                      <li> <router-link to="#">Blog</router-link></li>
                    </ul>
                  </div>
                </div>
                <div class="col-6 col-lg">
                  <div class="widget-link s2">
                    <h6 class="title">Products</h6>
                    <ul>
                      <li><router-link to="/spot/BTCUSDT">Spot</router-link></li>
                      <li><router-link to="/spot/BTCUSDT">Exchange</router-link></li>
                      <li><router-link to="/buy-crypto">Buy Crypto</router-link></li>
                      <li><router-link to="/staking">Earn</router-link></li>
                      <!-- <li><router-link to="/#">P2P Trading</router-link></li> -->
                    </ul>
                  </div>
                </div>
                <div class="col-6 col-lg">
                  <div class="widget-link s2">
                    <h6 class="title">Support</h6>
                    <ul>
                      <li><router-link to="/user/support">Support center</router-link></li>
                      <li><router-link to="/">Trading Fee</router-link></li>
                      <li><router-link to="/user/support">Submit Request</router-link></li>
                      <li><router-link to="/coming">Token Listing</router-link></li>
                      <li><router-link to="/coming">API Documentation</router-link></li>
                      <!-- <li><router-link to="/coming">IEO</router-link></li> -->
                      <!-- <li> <router-link :to="{ name: 'About', params: { slug: 'refferal_program' } }">
                    Referral Program</router-link></li> -->

                    </ul>
                  </div>
                </div>
                <div class="col-6 col-lg">
                  <div class="widget-link s3">
                    <h6 class="title">Buy Crypto</h6>
                    <ul class="footer">
                      <li><router-link to="/spot/BTCUSDT">BTC/USDT</router-link></li>
                      <li><router-link to="/spot/ETHUSDT">ETH/USDT</router-link></li>
                      <li><router-link to="/spot/BNBUSDT">BNB/USDT</router-link></li>
                      <li> <router-link to="/spot/TRXUSDT">TRX/USDT </router-link></li>
                    </ul>
                  </div>
                </div>
                <div class="col-6 col-lg d-none d-lg-block">
                  <div class="widget-link s3">
                    <h6 class="title">Download</h6>
                    <img :src="require(`../assets/home_images/qrcode2.png`)" width="120" />
                  </div>
                </div>
              </div>
          </div>
          <hr class="font-light-gray" />
        <div class="footer__bottom flex-lg-row flex-column-reverse gap-3 p-3">
          <div>
            <span class="text-secondary copyright">Copyright © 2023 tradehit All Rights Reserved</span>
          </div>
          <div>
            <ul class="list-social">
              <li v-for="(joinlist, index) in JoinData" :key="index">
                <a :href="'#'" class="text-white" target="_blank">
                  <i :class="'fa-brands fa-2x ' + joinlist.joinicon"></i>
                </a>
              </li>

            </ul>
          </div>
        </div>
        </div>
      </div>
    </footer>
    <div class="btn-group dropstart">
      <router-link :to="{name:'Support'}" class="chat shadow" type="button">
        <i class="fa-solid fa-headset fa-2x"></i>
      </router-link>
    </div>
  </div>
 
</template>

<script>
import ApiClass from "../api/api.js";
import Community from "@/assets/jsonfiles/Community.json";
import {
  required,
  email
} from "vuelidate/lib/validators";
export default {
  name: "Footer",
  data() {
    return {
      loggedIn: false,

      loading: false,
      submitted: false,
      email: "",
      JoinData: Community.CommunityLink,
    };
  },


  validations: {
    email: {
      required,
      email,
    },
  },


  mounted() {
    if (localStorage.getItem("token")) {
      this.loggedIn = true;
    }
    this.email = "";
  },


  methods: {
    async submitForm() {
      this.submitted = true;

      if (this.$v.email?.$invalid) {
        return;
      }
      this.submitted = false;
      this.loading = true;

      var form =
      {
        email: this.email
      }

      var result = await ApiClass.postRequest(
        "newsletter",
        false,
        form
      );
      if (result.data.status_code == 1) {

        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        this.email = "";
      } else {

        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }

      this.loading = false;

    },

  }

};
</script>
<style>
.dropstart {
  position: fixed;
  right: 3%;
  bottom: 5%;
  z-index: 1;
}

.dropstart .chat {
  border-radius: 50% !important;
  padding: .75rem !important;
  background-color: var(--primary) !important;
  color: var(--surface)!important;
}

.dropstart .dropdown-toggle::before {
  display: none !important;
}

.dropstart ul {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(-72px, 12px, 0px) !important;
}

.dropstart .dropdown-item:active {
  background-color: #fff6da !important;
}

.dropstart .dropdown-item:hover {
  background-color: #fff6da !important;
  color: #6e6e6e !important;
  transition: 0.27s;
}

.dropstart .dropdown-item:hover a {
  color: #6e6e6e !important;
  transition: 0.27s;
}

.dropstart .dropdown-menu {
  top: -90px !important;
  left: -160px !important;
  z-index: -1;
}
</style>