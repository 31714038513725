<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <!-- Toast Message Html 

    data-bs-autohide="true"
    -->
    <div class="toast text-white position-absolute p-3" data-bs-autohide="true" id="basicToast" style="z-index: 11">
      <div class="d-flex">
        <div class="toast-body" id="msgToast"></div>
        <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
          aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ApiClass from "./api/api.js";

export default {
  data: () => ({
    mobileAt: 991,
    
    maintenanceInt: null,
  }),
  components: {
    Header,
    Footer,
  },

  mounted() {
    console.log('dasdsad');
    // document.body.style.zoom = "90%";
    window.addEventListener("resize", this.resizeHandler);
    var value = window.innerWidth <= this.mobileAt ? true : false;
    this.$store.commit("changeMob", value);

    this.nodeHit();
  },

  methods: {
    resizeHandler(e) {
      var value = e.target.innerWidth <= this.mobileAt ? true : false;
      this.$store.commit("changeMob", value);
    },
    async nodeHit() {
      var data = await ApiClass.getNodeRequest("user/get/authority", false);
      var arr = data.data.data?.find(
        (element) => element.type == "maintenance"
      );

      if (arr) {
        this.$store.commit("initialMaintenance", arr.status);

        if (arr.status == "on") {
          this.maintenanceInt = setInterval(() => {
            this.$store.commit("initialMaintenance", arr.status);
            if (this.$router.currentRoute.path != "/maintenance") {
              localStorage.clear();
              window.location.href = window.location.origin + "/maintenance";
            }
          }, 1000);
        }
      }

      var socketConnection = new WebSocket(ApiClass.nodeWebsocket);

      socketConnection.onopen = function () {
        console.log("Successfully connected to the trade history...");
      };

      socketConnection.onclose = function (event) {
        console.log("Successfully Disconnected to the trade history...", event);
      };

      var Streamer = socketConnection;
      var $this = this;
      socketConnection.onopen = function onStreamOpen() {
        Streamer.onmessage = function (event) {
          // console.log(event.data);
          var status = JSON.parse(event.data).status;
          if (status) {
            $this.$store.commit("initialMaintenance", status);
            if (status == "on") {
              $this.maintenanceInt = setInterval(() => {
                $this.$store.commit("initialMaintenance", arr.status);
                if ($this.$router.currentRoute.path != "/maintenance") {
                  localStorage.clear();
                  window.location.href =
                    window.location.origin + "/maintenance";
                }
              }, 1000);
              //   $this.$router.push("/maintenance");
            } else {
              clearInterval($this.maintenanceInt);
              $this.$router.push("/");
            }
          }
        };
      };
    },
  },
};
</script>

<style>
.flash-message {
  z-index: 999;
  position: absolute;
}

.flash-message ._vue-flash-msg-body._vue-flash-msg-body_success {
  border: 1px solid #01947a;
  background-color: #01947a !important;
  width: fit-content;
}

._vue-flash-msg-body._vue-flash-msg-body_error {
  border: 1px solid #f12222;
  background-color: #f12222 !important;
  width: fit-content;
}

._vue-flash-msg-body._vue-flash-msg-body_error ._vue-flash-msg-body__content {
  /* border-left: 5px solid #f12222; */
  padding: 0;
}

._vue-flash-msg-body._vue-flash-msg-body_success ._vue-flash-msg-body__content {
  /* border-left: 5px solid #f12222; */
  padding: 0;
}

._vue-flash-msg-body__title {
  display: none;
}

._vue-flash-msg-body__text {
  margin: 0;
  padding: 8px 16px;
}

/****TOP****/
/* .left-bottom-position{  
    position: absolute;
    top: 71px;
    right: 0;
    height: 50px;
    padding: 0;
} */

/* div#basicToast {
    position: absolute;
    top: 71px;
    left: 0;
    height: 50px;
    padding: 0;
    z-index: 11
} */

/****BOTTOM****/
/* div#basicToast {
    position: absolute;
    bottom: 71px;
    right: 0;
    height: 50px;
    padding: 0;
} */


/** LIGHT **/
:root {
  --exchange-bg: #fff;
  --green: #20bca4;
  --red: #e35656;
  --white: #fff;
  --black: #000;
  --nav-active: #495057;
  --nav-bg: #f5f8fa;
  --serach-border: rgb(34 35 38 / 19%);
  --pair-list: #9b9b9b;
  --ticker-border: hsla(0, 0%, 59.2%, 0.2);
  --ticker-sub: #92989b;
  --fav-color: #ff9b00;
  --exchange-scroll: hsl(210deg 4% 78%);
  --box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  --upper-border: #cccc;
  --order-nav-bg: rgb(247, 250, 252);
  --depth-table-head: #5c5c5cf1;
  --order-depth-td: #000;
  --login-bg: rgba(255, 255, 255, 0.7);
  --login-span-color: rgb(28, 27, 33);
  --login-border-color: rgba(28, 27, 33, 0.07);
  --border-color: #f3f3f3;
  --chart-tab-btn: rgb(177, 177, 178);
  --limit-tab-bg: #f1f1f1;
  --input-border: #ced4da;
  --buy-active-color: #fff;
  --bg-red: rgb(255 61 0 / 11%);
  --bg-green: rgb(43 205 118 / 11%);

  /***home page var ***/
  --signup-btn: #f1a34f;
  --section-bg: #f5f8fb;
  --table-head-color: rgba(0, 0, 0, 0.5);
  --trade-bg: #ededed;
  --hover-link: #0066edba;
  --dark-bg: #17394f;
  --trading-form: #ededed;
  --social-link: #f1a34f;

  /*  */
  --bit-referral: rgb(23, 57, 79);
  --gray-text: rgba(111, 117, 131);
  --border-box: #386887;
}
</style>
