<template>
  <div>
    <div class="modal" id="deposit-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog card-tm modal-dialog-centered">
        <div class="modal-content card">
          <div class="modal-header border-0">
            <h5 class="modal-title">Deposit Crypto</h5>
            <button type="button" class="btn close-btn" @click="close" data-bs-dismiss="modal" aria-label="Close"
              ref="Close">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body p-4">
            <div class="col-md-12">
              <form class="row gy-4" @submit.prevent="submit">
                <div class="col-12">
                  <label class="fs-14 fw-500">Select Coin</label>
                  <v-select placeholder="Choose coin" class="border-0" id="setSymbol"
                    v-on:input="assignSymbol($event.title)" v-model="selected" :options="pairs" label="title">
                    <template slot="selected-option" slot-scope="option">
                      <div class="d-flex align-items-center">
                        <img v-if="selected.img" height="20" v-bind:src="option.img">
                        <img v-else height="20" v-bind:src="coin_img" />
                        <span style="position: relative; margin: 0px 5px">{{ option.title }}</span>
                      </div>
                    </template>
                    <template v-slot:option="option">
                      <img :src="option.img" style="width:20px; margin-right:8px;">{{ option.title }}
                    </template>
                  </v-select>
                </div>
                <div class="col-12" v-if="dataVisibleDeposit && deposit_net">
                  <label class="fs-14 fw-500">Select Network</label>
                  <div class="d-flex">
                    <ul class="nav rounded-3 nav-pills gap-2" role="tablist">
                      <li class="nav-item" role="presentation" v-for="(item, index) of deposit_net" :key="index">
                        <button class="nav-link" @click="assignDeposit(item)"
                          :class="item.id == showData.id ? 'active rounded-3' : ''" :id="`pills-${item.token_type}-tab`"
                          data-bs-toggle="pill" :data-bs-target="`#pills-${item.token_type}`" type="button" role="tab"
                          :aria-controls="`pills-${item.token_type}`" aria-selected="true"><i
                            class="fa fa-circle-check me-1" v-if="item.id == showData.id"></i> {{
                              alterTokenName(item.token_type)
                            }}</button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-if="loading" class="col-12 text-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div class="col-12" v-if="dataVisibleDeposit && deposit_net">
                  <div class="deposit_qrcode_img text-center">
                    <img :src="
                      generateQRCode(depositModal.toAddress)
                    " class="qr-code img-thumbnail img-fluid" alt />
                  </div>
                </div>
                <template v-if="dataVisibleDeposit && deposit_net">
                  <div class="col-12">
                    <div class="tab-content" id="pills-tabContent">
                      <div class="tab-pane fade show active" :id="'pills-home' + showData.token_type" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="deposit-main-box">
                          <label class="fs-14 pb-2 fw-500">{{ depositModal.itemCurrrency }} Deposit Address</label>
                          <div
                            class="usdt-div mb-2 overflow-auto d-flex justify-content-between align-items-center p-2 rounded-2">
                            <div class="">
                              <h6 class="mb-0 fs-14 fw-semibold">
                                {{ depositModal.toAddress }}
                              </h6>
                            </div>
                            <div type="button" :disabled="copy_disable" @click="copyAddress(depositModal.toAddress)">
                              <span v-if="copy_text == true"> <i class="fa fa-circle-check text-success"></i>
                              </span>
                              <span v-else><i class="fa-regular fa-copy"></i> </span>
                            </div>
                          </div>
                          <p v-if="this.depositModal.deposit_min">Min. amount <span class="fw-500">{{
                            this.depositModal.deposit_min }}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <label class="fs-14 pb-2 fw-500">Warnings</label>
                    <div class="min-deposit p-2 rounded-3">
                      <ol class="list">
                        <li>Min. 15 block confirmations are required for deposit this coin.</li>
                        <li v-show="dataVisibleDeposit">Send Only Using The
                          {{ networkInfo(showData.token_type).name }} ({{
                            showData.token_type == 'SELF' ?
                            networkInfo(showData.token_type).token_type :
                            showData.token_type
                          }}) Network. Using Any Other Network Will Result
                          In Loss Of Funds.
                        </li>
                        <li v-show="dataVisibleDeposit">Deposit Only
                          {{ networkInfo(showData.token_type).short }} To
                          This Deposit Address. Depositing any other asset or Non listed asset
                          Will Result In A Loss Of Funds.
                        </li>
                        <!-- <li v-for="item in warning" v-html="item" :key="item"></li> -->
                      </ol>
                    </div>
                  </div>
                </template>
                <template v-else v-show="dataVisibleDeposit">
                  <div class="col-12 alert alert-warning text-center">
                    <span class="warn-error">
                      {{ this.depositModal.message }}
                    </span>
                  </div>
                </template>
                <!-- <div class="min-deposit  p-3 mt-3">
                      <div class="d-flex justify-content-between">
                        <p class="m-0 fw-semibold fs-14">Min Deposit</p>
                        <p class="fs-12">{{ this.depositModal.deposit_min }}</p>
                      </div>
                      <div class="d-flex justify-content-between">
                        <p class="m-0 fw-semibold fs-14">Expected Arrival</p>
                        <p class="fs-12">15 Network Confirmation</p>
                      </div>
                      <div class="d-flex justify-content-between">
                        <p class="m-0 fw-semibold fs-14">Expected Lock</p>
                        <p class="fs-12">15 Network Confirmation</p>
                      </div>
                    </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
// import { Modal } from "bootstrap";
import ApiClass from "@/api/api";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Deposit",
  data() {
    return {
      loading: false,
      form: {
        title: "",
        category_id: 1,
        author_name: null,
        author_email: null,
        content: null,
      },
      submitted: false,
      selected: "Choose Coin",
      coin_img: null,
      pairs: [],

      currency_networks: [],
      deposit_net: [],
      showData: [],

      depositModal: {
        itemCurrrency: "",
        itemDeposit: "",
        message: "",
        toAddress: "",
        deposit_min: "",
      },
      copy_text: "Copy",
      copy_disable: false,
      base_currency: [],
      other_currency: [],
      total_currency: "",
      response: [],
      showData1: [],
      dataVisibleDeposit: false,
      dataVisibleWithdraw: false,
      total_freezed: null,
      warning: [
        'Please do not deposit any non - listed assets to the given address above, it will be lost.',
        `After your request has been filed, you will need to confirm the
      network node. After the 30 network confirmation, it will be credited. Once all 
      <strong class="text-primary">30 network confirmations </strong> are approved, you are allowed to withdraw your assets.`,
        `Your wallet address will not change. However, if it does, you are advised to file an inquiry.
       If there are any changes, we will notify you through our website announcements or via email.`,
        `To prevent information from being tampered with or leaked, please make sure that your computer and Internet browser are safe.`
      ]
    };
  },
  validations: {
    form: {
      title: { required },
      author_name: { required },
      author_email: { required, email },
      content: { required },
    },
  },
  async mounted() {

    this.selected = 'USDT';
    await this.assignSymbol(this.selected);
    // await this.callCoinsApi();
    await this.callApi();
  },
  methods: {
    close() {
      this.form.title = this.form.author_email = this.form.author_name = this.form.content = null;
      this.$refs.Close.click();
    },
    async submit() {
      this.submitted = true;
      if (this.$v.form.$invalid) {
        return;
      }
      this.loading = true
      await ApiClass.postRequest("ticket/create", true, this.form).then(
        (response) => {
          if (response.data.status_code == 1) {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.form = [];
            this.submitted = this.loading = false;
            return this.close();
          } else {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "error",
              title: response.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.submitted = this.loading = false;
          }
        }
      );
    },
    alterTokenName(token_type) {

      if ((this.depositModal.itemCurrrency == 'ETH') &&
        token_type == 'SELF') {
        return 'ERC20';
      }
      if ((this.depositModal.itemCurrrency == 'TRX') &&
        token_type == 'SELF') {
        return 'TRC20';
      }
      if ((this.depositModal.itemCurrrency == 'MCOIN') &&
        token_type == 'SELF') {
        return 'MCOIN20';
      }
      return token_type;

    },
    networkInfo(tType = null) {
      tType = tType?.substring(0, 3);
      if (tType == "ERC") return { name: "Ethereum", short: "ERC" };
      else if (tType == "TRC") return { name: "Tron", short: "TRC" };
      else if (tType == "BEP") return { name: "Binance", short: "BEP" };
      else if (tType == 'SEL' && this.depositModal.itemCurrrency == "MCOIN") return { name: "MCOIN", short: "MCOIN", token_type: "MCOIN20" };
      else if (tType == 'SEL' && this.depositModal.itemCurrrency != "MCOIN") {
        return (this.depositModal.itemCurrrency == 'ETH') ? { name: "Ethereum", short: "ERC", token_type: "ERC20" } : { name: "Tron", short: "TRC", token_type: "TRC20" };
      }
      else return { name: tType, short: tType };
    },
    async assignSymbol(event) {
      // if (event != this.$route.query.symbol) {
      //   this.$router.push({ path: this.$route.fullPath, query: { symbol: event } });

      // }
      await this.callApi(event);
      this.deposit(this.other_currency[0]);

    },
    assignDeposit(data = null) {
      this.showData = data;
      this.depositModal.toAddress = data.wallet_address;
      // console.log( data.wallet_address);
      this.depositModal.deposit_min = data.deposit_min;
      this.copy_text = "Copy";
      this.copy_disable = false;
    },


    copyAddress(mytext = null) {

      var input = document.createElement('input');
      input.setAttribute('value', mytext);
      input.value = mytext;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, 99999);
      var $this = this;
      var res = document.execCommand("copy");
      this.copy_text = res ? true : false;
      this.copy_disable = true;
      this.$swal({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Deposit Address Copied!",
        showConfirmButton: false,
        timer: 3000,
      });
      setTimeout(() => {
        $this.copy_text = "Copy";
        $this.copy_disable = false;
        document.body.removeChild(input);
      }, 2000);
    },

    generateQRCode(address) {
      return (
        "https://chart.googleapis.com/chart?cht=qr&chl=" +
        address +
        "&chs=160x160&chld=L|0"
      );
    },

    async callApi(symbol = null) {
      this.response = await ApiClass.getNodeRequest("user-crypto/get", true);
      if (this.response.data.status_code == 1) {
        this.loading = false;
        this.base_currency = this.response.data.data.filter((e) => {
          if (e.currency_type == "fiat" && e.symbol == symbol) {
            return e;
          }
        });

        this.other_currency = this.response.data.data.filter((e) => {
          if (e.currency_type == "crypto" && e.symbol == symbol) {
            return e;
          }

        });

        this.response.data.data.filter((e) => {
          if (e.symbol == symbol) {
            this.coin_img = e.image;
          }
          if (e.deposit_enable == true) {
            this.pairs.push({ "title": e.symbol, "img": e.image });
            this.pairs = this.$helpers.getUniqArrObj(this.pairs)
          }
        });

      }
      // console.log(this.response);
    },

    deposit(item) {
      // console.log('depoit enabled==============',item);
      this.depositModal.itemCurrrency = item.symbol;
      this.depositModal.token_type = item.token_type;
      this.depositModal.itemDeposit = item.deposit_enable;
      this.depositModal.message = item.deposit_desc;
      this.dataVisibleDeposit = item.deposit_enable;
      //console.log(item.currency_networks);
      if (this.dataVisibleDeposit) {
        this.deposit_net = item.currency_networks.filter(
          (e) => e.deposit_enable == true
        );

        if (this.deposit_net.length != 0) {
          this.showData = this.deposit_net[0];
          this.depositModal.toAddress = this.deposit_net[0].wallet_address;
          this.depositModal.deposit_min = this.deposit_net[0].deposit_min;

          // this.copy_disable = false;
          // this.copy_text = "Copy";
        }
      } else {
        this.dataVisibleDeposit = false;
      }
    },
  },
};
</script>

<style scoped>
.nav-pills .nav-link.active {
  border-color: var(--primary);
  background-color: transparent;
  color: var(--primary) !important;
}

.nav-pills .nav-link {
  border: 1px solid var(--bs-gray-400);
}

.deposit-crypto {
  min-height: 40vh;
}

.page-title .heading {
  margin-bottom: 0 !important;
  font-size: 24px !important;
}

.portfolio-transaction {
  padding: 0 !important;
}

.backbutton {
  display: none;
}

#deposit-modal ol {
  padding-left: 1rem;
}

#deposit-modal .list li {
  list-style: auto;
  font-size: 12px;
  line-height: 25px;
}

.label {
  font-weight: 500;
  color: #868686;
  font-size: 1rem;
}

.network-ul li button {
  background-color: #CC9A02 !important;
  color: white;
  font-weight: 600;
  padding: 0.5rem 3rem;
}

.network-ul li button:hover {
  background-color: #CC9A02 !important;
  color: white;
  font-weight: 600;
  padding: 0.5rem 3rem;
}

.usdt-div {
  background-color: var(--bg5);
}

.deposit-crypto .plan_active {
  position: relative;
}

.deposit-crypto .plan_active::after {
  content: "\f058";
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: 'FontAwesome';
  font-size: 18px;
  position: absolute;
  width: 0px;
  height: 0px;
  border-left: 16px solid transparent;
  right: 30px;
  top: 5px;
}

.usdt-div p {
  font-size: 13px;
}


.fa-circle-exclamation {
  color: rgb(255, 71, 71);
}

.warning-dis {
  font-size: 0.85rem;
}

th {
  color: var(--text-black);
}

.is_dark .nav-pills .nav-link.active {
  border: 1px solid var(--primary);
  color: #fff;
}

@media only screen and (min-width : 320px) {

  .network-ul li button {
    margin-bottom: 0.5rem !important;
  }


}

.modal-body {
  padding: 2rem;
}

/* .modal {
  background-color: #edf0f2bf;
} */

.modal {
  z-index: 9999;
}

.modal-header .close-btn {
  border-radius: 50%;
  padding: .2rem .6rem;
}

.modal-content {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border: none;
  border-radius: 3px;
}

.modal-header h5 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  /* color: rgb(72, 81, 86); */
  margin-bottom: 0;
}

.is_dark .modal-header button {
  color: white;
}

button#logout-blank {
  color: rgb(244, 67, 54);
  background: transparent;
  border-color: rgb(244, 67, 54);
  margin-right: 10px;
}

button.btn-close:focus {
  box-shadow: none;
  outline: none;
}

.modal-header {
  background: var(--bg5);
}
</style>
