<template>
    <div class="startup-sec card-tm">
        <div class="d-flex justify-content-center">
            <div class="col-11 px-3">
            <div class="row startup-top-row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="row gap-2" v-if="skeletonLoad">
                        <div v-for="item in 5" :key="item" class="col card shadow-sm p-0" style="border-radius: 1rem;">
                            <VueSkeletonLoader type="rect" class="w-100 mb-1" :width="350" :height="150"
                                animation="wave" color="#adb5bd" wave-color="#fff" :rounded="true" />
                            <VueSkeletonLoader type="rect" class="w-100" :width="40" :height="25" animation="wave"
                                color="#adb5bd" wave-color="#fff" :rounded="true" />
                        </div>
                    </div>

                    <swiper class="subbanner-slider pb-5 " :options="swiperOption" v-if="StartupSliderData.length"
                        :adaptiveHeight="true">

                        <swiper-slide class="card p-0 shadow-sm rounded-2" style="border-radius: 1rem;"
                            v-for="(startupslider, index) in StartupSliderData" :key="index">
                            <img :src="startupslider.image" class="img-fluid shadow" style="border-radius: 1rem;" />
                        </swiper-slide>
                    </swiper>
                </div>
            </div>

        </div>
        </div>
    </div>
</template>

<script>
 import ApiClass from "@/api/api";
// import tb_bitcoin from '../../assets/home_images/tb_bitcoin.svg';
import b1 from '../../assets/home_images/b1.svg';
import b2 from '../../assets/home_images/b2.svg';
import b3 from '../../assets/home_images/b3.svg';
import b4 from '../../assets/home_images/b4.svg';
import b5 from '../../assets/home_images/b5.svg';
import b6 from '../../assets/home_images/b6.svg';

import VueSkeletonLoader from "skeleton-loader-vue";
export default {
    name: "StartupBanner",
    components: {
        VueSkeletonLoader
    },

    data() {
        return {
            skeletonLoad: false,

            swiperOption: {
                slidesPerView: 5,
                spaceBetween: 10,
                slidesPerGroup: 1,
                loop: true,
                loopFillGroupWithBlank: true,
                pauseOnFocus: true,
                pauseOnHover: true,
                autoplay: {
                    delay: 3500,
                    disableOnInteraction: false,
                },
                breakpoints: {
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },

                    767: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                },
            },
            StartupSliderData: [
                { image: b1, link: '' },
                { image: b2, link: '' },
                { image: b3, link: '' },
                { image: b4, link: '' },
                { image: b5, link: '' },
                { image: b6, link: '' },
            ],


        };
    },


    async mounted() {
        var url = ApiClass.baseUrl;
        var ress = await fetch(url + "banner/get").then((result) => result.json());
        if (ress.status_code == "1") {
             this.StartupSliderData = ress.data;
            this.skeletonLoad = false;

        }

    },



};
</script>
