<template>
  <div>
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div class="modal" id="exampleModal-1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-sm card-tm modal-dialog-centered">
        <div class="modal-content card">
          <div class="modal-header border-0">
            <!-- <h5 class="modal-title" id="exampleModalLabel">Are you sure want to logout ?</h5> -->
            <button type="button" class="btn-close fs-8 border rounded-circle" data-bs-dismiss="modal" aria-label="Close" ref="Close"></button>
          </div>
          <div class="modal-body py-4">
            <div class="col-md-12">
              <div class="row justify-content-center text-center gy-4">
                <div class="px-3">
                    <p class="fw-500 fs-18">Are you sure you want to logout?</p>
                  </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center p-2 border-0">
              <div class="modal_btn" v-if="loading || loading2">
                  <div class="spinner-border text-light " role="status">
                  </div>
              </div>
              <div class="d-flex flex-column gap-2" v-else>
                  <button type="button" class="btn w-100 btn-primary" @click="logout('hardlogout')"
                  :disabled="disable">Logout from all devices</button>
                  <button type="button" class="btn w-100 text-primary fw-bold" @click="logout('logout')"
                  :disabled="disable">Logout from this device</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Modal } from "bootstrap";
import ApiClass from "../../api/api";

export default {
  name: "Modal",
  data() {
    return {
      theModal: null,
      loading: false,
      loading2: false,
      disable: false,
    };
  },
  mounted(){
    // this.theModal = new Modal(document.getElementById("exampleModal"));
  },
  methods: {
    async logout(url) {
      url == "logout" ? (this.loading2 = true) : (this.loading = true);
      this.disable = true;
      var res = await ApiClass.deleteRequest(url, true);
      if (res.data.status_code == 1) {
        this.$store.commit("RESET");
        let color = this.$theme;
        localStorage.clear();
        this.$store.commit("SET_THEME", color);
        this.$refs.Close.click();

        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        console.log("logout");
        // this.$router.go();

        location.replace("/spot/BTCUSDT");
        // this.$forceUpdate();
        // location.reload();
        //  this.$router.push("/spot/BTCUSDT")

        this;
      } else {
        this.disable = this.loading2 = this.loading = false;
        this.$refs.Close.click();
      }
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding: 2rem;
}
/* .modal {
  background-color: #edf0f2bf;
} */

.modal-content {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border: none;
  border-radius: 3px;
}

.modal-header h5 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: rgb(72, 81, 86);
  margin-bottom: 0;
}

button#logout-blank {
  color: rgb(244, 67, 54);
  background: transparent;
  border-color: rgb(244, 67, 54);
  margin-right: 10px;
}

button.btn-close:focus {
  box-shadow: none;
  outline: none;
}
</style>
