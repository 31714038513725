export default {

    getUniqueArray(arr) {
        return [...new Set(arr)];
    },

    getUniqArrObj(dataArr) {
        let jsonObject = dataArr.map(JSON.stringify);
        return Array.from(new Set(jsonObject)).map(JSON.parse);
    },

    NullishCoalescing(a, b) {
        return a?.[b] ?? [];
    },

    parsedate(days){
        var date = new Date().getDate() + days;
        return new Date(parseFloat(new Date().setDate(date))).toLocaleDateString();
    },

    NumberFixed(x,n){
        return Number.parseFloat(x).toFixed(n)
    }
}