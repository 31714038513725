<template>

    <div class="header__left">
        <div class="mobile-button">
            <i class="fas fa-ellipsis-v"></i>
        </div>
        <div class="logo">
            <router-link class="light" to="/"><img :src="site_img_night" class="img-fluid" alt="" width="130" />
            </router-link>
            <router-link class="dark" to="/"><img :src="site_img_night" class="img-fluid" alt="" width="130" />
            </router-link>
        </div>
        <div class="left__main">
            <nav id="main-nav" class="main-nav">
                <ul id="menu-primary-menu" class="menu">
                    <li class="nav-item dropdown" v-for="(item, i) in navbar" :key="i">
                    <template v-if="Array.isArray(Object.values(item)[0])">
                        <a class="nav-link dropdown-toggle" role="button"
                            data-toggle="dropdown" aria-expanded="false">
                            {{ Object.keys(item)[0] }} <i class="fa-solid fa-chevron-down fs-12 d-lg-inline d-none"></i>
                        </a>
                        <ul class="dropdown-menu border-0 rounded-4 shadow">
                            <li v-for="(items, j) of Object.values(item)[0]" :key="j">
                                <router-link class="dropdown-item py-2 d-flex align-items-center gap-3"
                                    :to="!items.live ? '/coming' : `${items.link}`">
                                    <!-- <img :src="require(`@/assets/images/${$theme == 1 ? `icons/dark/${items.icon}` : `icons/${items.icon}`}`)"
                                        class="img-fluid rounded-circle" width="28" /> -->
                                    <div class="w-100">
                                        <div class="d-flex justify-content-between align-items-center ">
                                            <span class="fw-600 text-primary">{{ items.title }}</span>
                                       
                                        </div>
                                        <p class="fs-12 mt-1">{{ items.subtitle }}</p>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </template>
                    <template v-else>
                        <router-link class="nav-link" :to="`/${Object.values(item)[0].link}`">{{
                            Object.keys(item)[0]
                        }}</router-link>
                    </template>
                </li>
                    <!-- Buy Crypto -->
                    
                    <!-- <li class="menu-item" @click="closeToggle">
                        <router-link class="nav-link" to="/spot/BTCUSDT">Spot & Trade</router-link>
                    </li> -->
                    <!-- Trade -->
                    <!-- <li class="menu-item dropdown" @click="closeToggle">
                        <a class="nav-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            Spot & Trade <i class="fa-solid fa-chevron-down fs-12"></i>
                        </a>
                        <div class="dropdown-menu rounded-2" aria-labelledby="dropdownMenuButton">
                            <router-link class="dropdown-item py-2 d-flex align-items-center gap-2"
                                to="/spot/BTCUSDT">
                                Spot
                            </router-link>
                            <router-link class="dropdown-item py-2 d-flex align-items-center gap-2" to="/coming">
                                Margin
                                <span id="spot-pill" class="badge bg-primary fs-8">coming</span></router-link>
                        </div>
                    </li> -->
                    <!-- Hold Earn -->
                    <!-- <li class="menu-item dropdown" @click="closeToggle">
                        <a class="nav-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            Finance <i class="fa-solid fa-chevron-down fs-12"></i>
                        </a>
                        <div class="dropdown-menu rounded-2" aria-labelledby="dropdownMenuButton">
                            <router-link class="dropdown-item py-2 d-flex align-items-center gap-3" to="/coming">
                                Hold & Earn
                                <span id="spot-pill" class="badge bg-primary small fs-8">coming</span>
                            </router-link>
                            <router-link class="dropdown-item py-2 d-flex align-items-center gap-3" to="/coming">
                                Launchpad <span id="spot-pill"
                                    class="badge bg-primary small fs-8">coming</span></router-link>
                        </div>
                    </li> -->
                    <!-- Market -->
                    <!-- <li class="menu-item" @click="closeToggle">
                        <router-link class="nav-link" to="/market">Markets</router-link>
                    </li> -->
                </ul>
            </nav>
            <!-- /#main-nav -->
        </div>
    </div>
</template>
<script>

export default {
    name: "LeftSide",


    data() {
        return {
            loggedIn: false,
            toggle: false,
            notification: [],
            unread: "",
            themeColor: 0,
            navbar: [
               
                {
                    'Buy Crypto': { 'link': 'buy-crypto' },
                },
                
                {
                    'Trade': [
                        { title: 'Basic Trading', subtitle: 'Buy & sell spot with one click', link: '/spot/BTCUSDT', icon: 'spot.svg', live: true },
                        { title: 'Expert Trading', subtitle: 'Trade with available funds ', link: '/spot/BTCUSDT', icon: 'margin.svg', live: true },
                    ]
                },
                {
                    'Finance': [
                        { title: 'Hold & Earn', subtitle: 'Get Benefit of Holdings', link: '/staking', icon: 'earn.svg', live: true },
                    ]
                },
                {
                    'Market': { 'link': 'market' },
                },
                {
                    'Launchpad': { 'link': 'launchpad' },
                },
                {
                    'Apply For Listing': { 'link': 'apply_listing' },
                },
                 {
                    'More': [
                        { title: 'P2P Crypto', link: '/coming', },
                        { title: 'NFT Marketplace', link: '/coming', },
                        { title: 'Refferal Program',link: '/account/referral-friends', live: true },
                    ]
                },
   
            ],
        };
    },
    mounted() {
        if (localStorage.getItem("token")) {
            this.loggedIn = true;
        }
        document.addEventListener('click', this.close)
        this.themeColor = this.$store.getters.getTheme;
    },
    methods: {
        closeToggle() {
            // document.getElementById('main-nav-mobi').style.display = "none";
            // const menuToggle = document.getElementById("navbarNav");
            // menuToggle.classList.remove("show");
        },
        show() {
            if (this.unread != 0) {
                this.updateNotification();
            }
            this.toggle = !this.toggle;
        },

        close(e) {
            if (!this.$el.contains(e.target)) {
                this.toggle = false
            }
        },

    },

    async beforeCreate() { },
    watch: {
        $route() {
            if (localStorage.getItem("token")) {
                this.loggedIn = true;
            }
        },
        "$store.getters.getTheme": function () {
            this.themeColor = this.$store.getters.getTheme;
        },
    },
};
</script>
<style>
#header_main .form-control{
  background-color: white!important;
}
</style>
