<template>
    <div class="header__right align-items-baseline">

        <div class="auth" v-if="!loggedIn">
            <ul class="nav gap-2">
                <li class="menu-item login-btn-mobie border rounded" @click="closeToggle">
                    <router-link class="nav-link" to="/login">
                        <!-- <i class="fa fa-user-circle"></i> -->
                        <span> Login</span>
                    </router-link>
                </li>
                <li class="menu-item get-started rounded d-none d-md-block" @click="closeToggle">
                    <router-link class="nav-link text-white" to="/register">
                        <!-- <i class="fas fa-user-plus"></i> -->
                        Register
                    </router-link>
                </li>
            </ul>
        </div>


        <!-- <div class="dropdown" v-if="loggedIn" @click="closeToggle">
            <a class="nav-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="d-md-block d-none">Wallet <i class="fa-solid fa-chevron-down fs-12"></i></span>
                <span class="d-block d-md-none"> <i class="fa-solid fa-wallet"></i></span>
            </a>
            <div class="dropdown-menu rounded-4" aria-labelledby="dropdownMenuButton">
                <router-link class="dropdown-item py-2 d-flex align-items-center gap-3" :to="item.live ? item.link : ''"
                    v-for="(item, index) in walletItems" :key="index">
                    {{ item.name }}
                    <span id="spot-pill" class="badge bg-primary fs-8" v-if="!item.live">coming</span>
                </router-link>
            </div>
        </div> -->
        <div class="menu-item d-none d-md-inline" v-if="loggedIn">
            <router-link class="nav-link btn-primary rounded-5" v-if="loggedIn" to="/wallet/deposit?symbol=USDT">Deposit</router-link>
            <!-- <button type="button" class="nav-link text-white fw-600 btn-primary rounded-5" data-bs-toggle="modal" data-bs-target="#deposit-modal">Deposit</button> -->
        </div>
        <div class="menu-item ms-2">
            <router-link class="nav-link border rounded-5" v-if="loggedIn" to="/wallet/dashboard">Wallet</router-link>
        </div>


        <div class="dropdown user" v-if="loggedIn" @click="closeToggle">
            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton5" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <!-- <div class=" fw-bold fs-5 dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <img src="../../../public/assets/images/avt/user.svg" alt="" />
                </div> -->
                <i class="fa-solid fa-lg fa-circle-user"></i>
            </button>
            <ul class="dropdown-menu shadow  border-0 py-0" aria-labelledby="dropdownMenuButton5">
                
                <li v-for="(items, j) of dropdownItems" :key="j">
                    <router-link class="dropdown-item py-2 d-flex align-items-center gap-3"
                        :to="!items.live ? '/coming' : `${items.link}`">
                        <!-- <img :src="require(`@/assets/images/${$theme == 1 ? `icons/dark/${items.icon}` : `icons/${items.icon}`}`)"
                            class="img-fluid rounded-circle" width="28" /> -->
                        <div class="w-100">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="fw-500 text-primary">{{ items.title }}</span>
                                <span id="spot-pill" class="badge bg-primary small fs-10" v-if="!items.live">coming</span>
                            </div>
                            <p class="fs-12">{{items.subtitle}}</p>
                        </div>
                    </router-link>
                </li>
                <hr class="m-0">
                <li class="align-items-center custom-padding custom-gap d-flex dropdown-item">
                    <!-- <img :src="require(`@/assets/images/icons/logout.svg`)" class="img-fluid" width="28"/> -->

                    <a href="javascript:void()" class="item text-wrap align-items-start color-primary d-flex text-decoration logout"
                        data-bs-toggle="modal" data-bs-target="#exampleModal-1" id="logout">
                        Log Out
                    </a>
                </li>
            </ul>
        </div>
        <div class="dropdown user d-lg-block d-none" @click="closeToggle">
            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton5" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <!-- <div class=" fw-bold fs-5 dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <img src="../../../public/assets/images/avt/user.svg" alt="" />
                </div> -->
                <img :src="require(`@/assets/images/icons/dark/download.svg`)" width="20" class="img-fluid"/>
            </button>
            <ul class="dropdown-menu download shadow  border-0 py-0 text-center" aria-labelledby="dropdownMenuButton5">
                <img :src="require(`@/assets/home_images/qrcode2.png`)" class="img-fluid" width="120" />
                <span id="spot-pill" class="badge bg-primary fs-8">coming</span>
                <h6 class="fw-500 fs-12 my-2">Scan to Download App IOS & Android</h6>
                <!-- <div class="d-flex align-items-center flex-column  gap-3 p-3 shadow">
                    <h6 class="fw-600 fs-16 my-2">Scan to Download App IOS & Android</h6>
                </div> -->
            </ul>
        </div>
        <div class="mode-switcher">
            <a class="sun" href="#" @click="changeTheme" onclick="switchTheme()">
                <img :src="require(`@/assets/images/icons/dark/sun.svg`)" class="img-fluid" width="20" />
            </a>
            <a href="#" class="moon" @click="changeTheme" onclick="switchTheme()">
                <img :src="require(`@/assets/images/icons/dark/moon.svg`)" class="img-fluid" width="20" />
            </a>
        </div>
        <Modal />
        <DepositModal v-if="loggedIn"/>
    </div>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import DepositModal from "@/components/modal/Deposit.vue";
import ApiClass from "../../api/api";

export default {
    name: "RightSide",
    components: {
        Modal,
        DepositModal
    },

    data() {
        return {
            item: null,
            themeColor: 0,
            loggedIn: false,
            userData: null,
            toggle: false,
            notification: [],
            unread: "",
            user: {},
            walletItems: [
                { 'name': 'Overview', 'icon': 'overview.svg', 'link': '/wallet/dashboard', 'live': true },
                { 'name': 'Deposit', 'icon': 'deposit.svg', 'link': '/wallet/deposit?symbol=USDT', 'live': false },
                { 'name': 'Withdraw', 'icon': 'withdraw.svg', 'link': '/wallet/withdraw?symbol=USDT', 'live': false },
                { 'name': 'Fiat & Spot', 'icon': 'fiat.svg', 'link': '/wallet/spot', 'live': false },
                { 'name': 'Staking & Earn', 'icon': 'earn.svg', 'link': '/wallet/staking', 'live': false },
                // { 'name': 'Transaction History', 'icon': 'history.svg', 'link': '/wallet/history' },
            ],
            // dropdownItems: [
            //     { 'name': 'Profile', 'icon': 'profile.svg', 'link': '/settings/account', 'live': true },
            //     { 'name': 'KYC', 'icon': 'kyc.svg', 'link': '/coming', 'live': false },
            //     { 'name': 'Security', 'icon': 'verify.svg', 'link': '/coming', 'live': false },
            //     // { 'name': 'Referral Center', 'icon': 'refferal.svg', 'link': '/coming', 'live': false },
            //     // { 'name': 'My Fees', 'icon': 'fee.svg', 'link': '/coming', 'live': false },
            //     // { 'name': 'My Tickets', 'icon': 'ticket.svg', 'link': '/coming', 'live': false },
            // ],
            dropdownItems: [
                    { title: 'Profile', icon: 'kyc.svg', subtitle: 'Your Account Details', live: true, link: '/settings/account' },
                    { title: 'KYC', icon: 'kyc.svg', subtitle: 'Complying Account Kyc', live: true, link: '/settings/kyc' },
                    { title: 'Security', icon: 'verify.svg', subtitle: 'Check security level', live: true, link: '/settings/security' },
                    { title: 'Referral Program', icon: 'refferal.svg', subtitle: 'Invite friends double your earnings', live: true, link: '/account/referral-friends' },
                    // { title: 'My Fees', icon: 'fee.svg', subtitle: 'Breaking Down and Analyzing the Costs', live: true, link: '/settings/fees' },
                    // { title: 'My Tickets', icon: 'ticket.svg', subtitle: 'Resolving Issues Quickly and Effectively', live: true, link: '/ticketlist' },
                ],
        };
    },
    mounted() {
        if (localStorage.getItem("token")) {
            this.loggedIn = true;
            this.getNotifications();
            this.getUserInfo();
            this.userDatas();
        }
        document.addEventListener('click', this.close)
        this.themeColor = this.$store.getters.getTheme;
        this.changeTheme();
    },
    methods: {
        userDatas() {
            var userdata = localStorage.getItem("user");
            this.user = JSON.parse(userdata);
        },
        closeToggle() {
            // document.getElementById('main-nav-mobi').style.display = "none";
            // const menuToggle = document.getElementById("navbarNav");
            // menuToggle.classList.remove("show");
        },
        getCookie() {
            // var name = cname + "="
            var name = "theme="
            var ca = document.cookie.split(';')
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1)
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length)
                }
            }
        },
        changeTheme() {
            let themeColor = (this.getCookie("theme") == "is_dark" ? 1 : 0)
            this.$store.commit("SET_THEME", themeColor);
        },
        show() {
            if (this.unread != 0) {
                this.updateNotification();
            }
            this.toggle = !this.toggle;
        },
        async getUserInfo() {
            await ApiClass.getNodeRequest('user/get', true)
                .then((res) => {
                    if (res.data.status_code == 1) {
                        this.userData = res.data.data;
                        this.status = res.data.data.status == true ? 'Active' : 'Blocked';
                    }

                })

        },
        async updateNotification() {
            if (localStorage.getItem("token")) {
                let response = await ApiClass.updateFormRequest("notification/read", true);

                if (response.data.status_code != 1) {
                    return;
                }

                this.unread = 0;
            }
        },
        close(e) {
            if (!this.$el.contains(e.target)) {
                this.toggle = false
            }
        },
        async getNotifications() {
            let response = await ApiClass.getRequest("notification/get", true);

            if (response.data.status_code != 1) {
                return;
            }

            if (response?.data) {
                this.unread = response.data.data.totalcount ?? 0;
                this.notification = response.data.data.notifications ?? [];
            }
        }
    },

    async beforeCreate() { },
    watch: {
        $route() {
            if (localStorage.getItem("token")) {
                this.loggedIn = true;
                this.getUserInfo();
            }
        },
        "$store.getters.getTheme": function () {
            this.themeColor = this.$store.getters.getTheme;
        },
    },

};
</script>

<style>
.border {
    border-color: #242f36 !important;
}

.text-decoration {
    text-decoration: none !important;
}


.dropdown-menu .dropdown-item {
    border-radius: 0.3rem;
    font-size: 14px;
}

.custom-gap {
    gap: 1.1rem !important;
}

.dropdown-item:active {
    background-color: #f8e7ad !important;
}

.dropdown-item:hover {
    background-color: var(--hover-text);
    color: var(--primary-2) !important;
    transition: 0.27s;
}

.dropdown-item:hover a {
    color: var(--primary-2) !important;
    transition: 0.27s;
}

.custom-color {
    color: var(--onsurface) !important;
}



.custom-padding {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
}

.dropdown-toggle::after {
    transition: transform .7s ease-in-out !important;
    transform: rotate(360deg) !important;
}

.dropdown-toggle::after:hover {
    transform: rotate(360deg) !important;
}

/* dropdown css end */


.dropdown-toggle::after {
    display: none !important;
}

.account ul {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate3d(-73px, 50.4px, 0px) !important;
}

.custom_prop:hover {
    background-color: transparent !important;
    transition: 0.27s;

}

.custom_prop {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    cursor: default;
    color: #7a7a7a;
    font-weight: 500;
    font-size: 0.879rem;
}

.uid_div {
    color: #9a9a9a;
    font-weight: 400;
    font-size: 0.8rem;
}

.volume-div {
    background-color: #fff1c5 !important;
    color: #7c7c7c;
    font-weight: 400;
    font-size: 0.82rem;

}

#header_main .header__right .dropdown-menu.download {
    max-width: 150px !important;
    min-width: 140px !important;
}
</style>