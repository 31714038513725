<template>
        <header id="header_main" class="header">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="header__body d-flex justify-content-between align-items-center">
                              
                              <LeftSide/>
                              <RightSide/>
                             
                        </div>
                    </div>
                </div>
            </div>
            <Modal />
        </header>

</template>

<script>
import RightSide from "@/components/header/RightSide.vue";
import LeftSide from "@/components/header/LeftSide.vue";
import Modal from "@/components/modal/Modal.vue";

export default {
    name: "Header",
    components: {
        Modal,
        LeftSide,
        RightSide
    },

  

  
};
</script>
<style>

.nav-link {
    font-weight: 500;
}
#header_main .dropdown-menu {
    min-width: max-content !important;
    padding: 8px;
    top: 35% !important;
    border-radius: .3rem!important;
}
#header_main .header__right .dropdown-menu {
    min-width: max-content !important;
    padding: 8px!important;
    top: 0 !important;
    border-radius: .3rem!important;
}
</style>
